<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="new_add_link_modal"
      ref="new_add_link_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_add')"
      footer-class="d-none"
      size="md"
      @hide="close_new_add_link_modal"
    >
      <div>
        <validation-observer
          ref="addForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="new_add_link_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('biolink_designer.title') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="new_link_add_title"
                      :state="errors.length > 0 ? false: new_link_add_title != null ? true : null"
                      :placeholder="$t('biolink_designer.title')"
                      name="name"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('biolink_designer.url') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required|url"
                  >
                    <b-form-input
                      v-model="new_link_add_url"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('biolink_designer.url')"
                      name="name"
                      type="text"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('biolink_designer.invalid_url') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                <span class="align-middle">{{ $t('core.btn_add') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Düzenle Modal-->
    <b-modal
        id="edit_link_modal"
        ref="edit_link_modal"
        centered
        no-close-on-backdrop
        content-class="shadow"
        :title="$t('core.model_title_update')"
        footer-class="d-none"
        size="md"
        @hide="close_edit_link_modal"
    >
      <div>
        <validation-observer
            ref="addForm"
            #default="{invalid}"
        >
          <b-form @submit.prevent="new_add_link_button">
            <b-row>
              <b-col
                  cols="12"
                  class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('biolink_designer.title') }}</label>
                  <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                  >
                    <b-form-input
                        v-model="link_edit_title"
                        :state="errors.length > 0 ? false: link_edit_title != null ? true : null"
                        :placeholder="$t('biolink_designer.title')"
                        name="name"
                    />
                    <small
                        v-if="errors[0]"
                        class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  cols="12"
                  class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('biolink_designer.url') }}</label>
                  <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required|url"
                  >
                    <b-form-input
                        v-model="link_edit_url"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('biolink_designer.url')"
                        name="name"
                        type="text"
                    />
                    <small
                        v-if="errors[0]"
                        class="text-danger"
                    >{{ $t('biolink_designer.invalid_url') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end">
              <b-button
                  size="sm"
                  variant="primary"
                  class="mr-1"
                  type="submit"
                  :disabled="invalid"
              >
                <span class="align-middle">{{ $t('core.model_title_update') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Düzenle Modal-->

    <b-row>
      <b-col
        cols="5"
        class="bg-white"
      >
        <!-- Icon Select            -->
        <!-- Icon Select            -->
        <b-row class="justify-content-center my-3">
          <div class="phone-frame">
            <div class="phone-content">
              <b-row
                v-for="(listItem, index) in biolink_data.links"
                :key="index"
                class="justify-content-center"
              >
                <b-col cols="12">
                  <b-button target="_blank" :href="listItem.url" class="my-25" block>{{ listItem.title }}</b-button>
                </b-col>
              </b-row>
            </div>
            <div class="phone-footer" />
          </div>
        </b-row>
      </b-col>
      <b-col
        cols="7"
        class="bg-light-secondary"
      >
        <b-row class="justify-content-start m-4">
          <b-col cols="12">
            <b-tabs>
              <b-tab>
                <template #title>
                  <feather-icon icon="LinkIcon" />
                  <span>Linkler</span>
                </template>
                <b-row>
                  <b-col cols="8">
                    <b-row>
                      <b-col cols="8">
                        <b-button
                          v-b-modal.new_add_link_modal
                          block
                        >+ Link Ekle</b-button>
                      </b-col>
                      <b-col cols="4">
                        <b-button
                          v-b-modal.new_add_link_modal
                          variant="danger"
                          block
                        >+ APP Ekle</b-button>
                      </b-col>
                    </b-row>
                    <hr class="border-info">
                  </b-col>
                  <b-col cols="8">
                    <b-row>
                      <b-col cols="12">
                        <draggable
                            :list="biolink_data.links"
                            :group="{name: 'tags'}"
                            class="mb-1 cursor-move"
                        >
                          <b-row
                              v-for="(listItem, index) in biolink_data.links"
                              :key="index"
                              class="my-50"
                          >
                            <b-col
                                cols="12"
                                class="py-1 border border-primary text-black bg-white"
                            >
                              <b-row>
                                <b-col cols="10">
                                  <b><span class="d-flex">{{ listItem.title }}</span></b>
                                  <span class="d-flex">{{ listItem.url }}</span>
                                </b-col>
                                <b-col cols="2">
                                  <b-button
                                      @click="rowUpdateModal(listItem)"
                                      variant="primary"
                                      size="sm"
                                  >
                                    <feather-icon icon="EditIcon" />
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </draggable>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <h6>Sosyal Medya</h6>
                        <b-button block>+ Sosyal Medya Ekle</b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon icon="ImageIcon" />
                  <span>Tasarım</span>
                </template>
                ssss
              </b-tab>
              <b-tab>
                <template #title>
                  <i class="pi pi-language" />
                  <span>Çeviri</span>
                </template>
                ssss
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BModal,
  BRow, BTab, BTabs,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import draggable from 'vuedraggable'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@core/utils/validations/validations'
import vSelect from "vue-select"; // PrimeIcons listesini içeren dosya

export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    BModal,
    BFormCheckbox,
    BFormInput,
    BBadge,
    draggable,
    BTabs,
    BTab,
    BRow,
    BButton,
    BCol,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      selected_row: null,
      new_link_add_title: null,
      new_link_add_url: null,
      link_edit_title: null,
      link_edit_url: null,
      yaziniz: 'Bu, telefon çerçevesi içinde gösterilecek yazıdır.',
      biolink_data: {
        template_design: {},
        links: [
          { type: 'link', title: 'Google', url: 'https://google.com' },
          { type: 'link', title: 'Youtube', url: 'https://youtube.com' },
        ],
        social_media: [],
      },
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {},
  methods: {
    new_add_link_button() {
      this.biolink_data.links.push({
        type: 'link',
        title: this.new_link_add_title,
        url: this.new_link_add_url,
      })
      this.close_new_add_link_modal()
    },
    rowUpdateModal(selected_row) {
      this.selected_row = selected_row
      this.link_edit_title = selected_row.title
      this.link_edit_url = selected_row.url
      this.$refs.edit_link_modal.show()
    },
    close_new_add_link_modal() {
      this.$refs.new_add_link_modal.hide()
      this.new_link_add_title = null
      this.new_link_add_url = null
    },
    close_edit_link_modal() {
      this.$refs.edit_link_modal.hide()
      this.selected_row = null
    },
  },
}
</script>
<style scoped>
.phone-frame {
  position: relative;
  width: 300px;
  height: 600px;
  border: 16px solid black;
  border-radius: 36px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.phone-header {
  width: 100%;
  height: 40px;
  background: black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.phone-content {
  position: absolute;
  top: 40px; /* header'ın yüksekliği */
  left: 0;
  width: 100%;
  height: calc(100% - 80px); /* header ve footer yüksekliği */
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}

</style>
